jQuery(window).on('load',function(){

    top_carousel();

	pils_home_page();

	buttons_move_anim();

	noty_infos();

	location_function();

	fancy();

	messages_all_counter();
	blinks_counter();
	activities_counter();
	messages_only_counter();

	visits_counter();

	scroll_top();
	
	check_facebook_reg();

});


function check_facebook_reg(){


	jQuery('.validate.formtastic.user .button_save').on('click touchstart', function(event){
		
		var check_ = false;
		
		var terms = false;
		var personal = false;



		if ( jQuery('#user_profile_attributes_terms').is(':checked')){

			terms = true

		}else{

			generate_10('error', 'Zaakceptuj regulamin portalu. ')
			
			return false;
			
		}

		if ( jQuery('#user_profile_attributes_personal_data').is(':checked')){

			personal = true

		}else{

			generate_10('error', 'Wyraź zgodę na przetwarzanie danych osobowych zgodnie z polityką prywatności portalu. ')
			
			return false;

		}

		// LOGIN
		if ( jQuery('#user_uname').val().length >= 4 ){
			
			check_ = true;


		}else{

			generate_10('error', 'Uzupełnij: Nazwę profilu')
			
			return false;
			
		}


	});

}



function scroll_top(){
	'use strict';
		
		var d = jQuery('body');
		if (navigator.userAgent.match(/Android|IEMobile|BlackBerry|iPhone|iPad|iPod|Opera Mini/i)) {
	
				$('.right_top_scroll').bind('touchstart', function (e) {
					jQuery('html,body').animate({ scrollTop: "0px" });
				});
				
		}else{
			
			jQuery('.right_top_scroll').on('click',function(){
				jQuery('html,body').animate({ scrollTop: "0px" });
			});
		
		}
	
}
	


function visits_counter(){

	jQuery('.option_box .visits_counter p').each( function() {

		jQuery('.option_box .visits_counter').hide();

	    jQuery.ajax({
	      url: '/visits/unread_count',
	      dataType: 'json',
	      success: function(data) {
	        if (data > 0) {
			  jQuery('.option_box .visits_counter').show();
	          jQuery('.option_box .visits_counter p').html(data);
	        }
	     }
	    });

  	});


}



function messages_only_counter(){

	jQuery('.messages_incoming .msg_counter p').each( function() {

		jQuery('.messages_incoming .msg_counter').hide();

	    jQuery.ajax({
	      url: '/messages/unread_count_no_activities',
	      dataType: 'json',
	      success: function(data) {
	        if (data > 0) {
			  jQuery('.messages_incoming .msg_counter').show();
	          jQuery('.messages_incoming .msg_counter p').html(data);
	        }
	     }
	    });

  	});


 }


function messages_all_counter(){

	jQuery('.option_box .msg_counter p').each( function() {

		jQuery('.option_box .msg_counter').hide();

	    jQuery.ajax({
	      url: '/messages/unread_count',
	      dataType: 'json',
	      success: function(data) {
	        if (data > 0) {
			  jQuery('.option_box .msg_counter').show();
	          jQuery('.option_box .msg_counter p').html(data);
	        }
	     }
	    });

  	});


 }


 function blinks_counter(){

	jQuery('.blinks_incoming .msg_counter p').each( function() {

		jQuery('.blinks_incoming .msg_counter').hide();

	    jQuery.ajax({
	      url: '/blinks/unread_count',
	      dataType: 'json',
	      success: function(data) {
	        if (data > 0) {
			  jQuery('.blinks_incoming .msg_counter').show();
	          jQuery('.blinks_incoming .msg_counter p').html(data);
	        }
	     }
	    });

  	});


 }



 function activities_counter(){

	jQuery('.activities_incoming .msg_counter p').each( function() {

		jQuery('.activities_incoming .msg_counter').hide();

	    jQuery.ajax({
	      url: '/activities/unread_count',
	      dataType: 'json',
	      success: function(data) {
	        if (data > 0) {
			  jQuery('.activities_incoming .msg_counter').show();
	          jQuery('.activities_incoming .msg_counter p').html(data);
	        }
	     }
	    });

  	});


 }

function fancy(){
	'use strict';

	jQuery('.profile_photos_container .profile_main_photo').each( function(){

			
		jQuery(this).find('a.fancybox').fancybox({

			beforeLoad : function(){
				
				jQuery('body').removeClass('fancybox-active').removeClass('compensate-for-scrollbar');
				
			},
			beforeClose : function(){
				
			}

		});
	
	});


	jQuery('.more_images .photo').each( function(){

			
		jQuery(this).find('a.fancybox').fancybox({

			beforeLoad : function(){
				
				jQuery('body').removeClass('fancybox-active').removeClass('compensate-for-scrollbar');
				
			},
			beforeClose : function(){
				
			}

		});
	
	});

}

	


function location_function(){
	
	$("select#profile_state_id ,select#search_state_id ").change(function(){
		
	            var id_value_string = $(this).val();
				
	            if (id_value_string == "") { 
	                // if the id is empty remove all the sub_selection options from being selectable and do not do any ajax
	                $("select#search_city_id option, select#profile_city_id option").remove();
	                var row = "<option value=\"" + "" + "\">" + "" + "</option>";
	                $(row).appendTo("select#search_city_id, select#profile_city_id");
					
	            }else {
					
	                // Send the request and update sub category dropdown 
	                $.ajax({

	                    dataType: "json",
	                    cache: false,
	                    url: '/cities/for_sectionid/' + id_value_string,
	                    timeout: 2000,
	                    error: function(XMLHttpRequest, errorTextStatus, error){
	                        
	                    },
	                    success: function(data){           
		         			
	                        // Clear all options from sub category select 
	                        $("select#search_city_id option, select#profile_city_id option").remove();
	                        //put in a empty default line
	                        var row = "<option value=\"" + "" + "\">" + "" + "</option>";
	                        $(row).appendTo("select#search_city_id ,select#profile_city_id");   
							//$('select.chosen-select').trigger("chosen:updated");         
							       
	                        // Fill sub category select 
							
	                        $.each(data, function(i, j){
								
	                            row = "<option value=\"" + j.id + "\">" + j.name + "</option>";  
	 							//alert(row);
	                            $(row).appendTo("select#profile_city_id, select#search_city_id");   
								//$('select#profile_city_id, select#search_city_id').selectBox('refresh');
	                  			//$('select.chosen-select').trigger("chosen:updated");
								
	                        });
	                                   
	                     }
	                });
	            };
	});
	
	
	
}


function generate_10(type,text) {
	var n = noty({
		text: text,
		type: type,
		timeout : 5000,
		dismissQueue: true,
		maxVisible: 5,
		layout: 'topRight',
		theme: 'defaultTheme'

	});
}


function generate_20(type,text) {
	var n = noty({
		text: text,
		type: type,
		timeout : 20000,
		dismissQueue: true,
		maxVisible: 5,
		layout: 'topRight',
		theme: 'defaultTheme'

	});
}



function generate_30(type,text) {
	var n = noty({
		text: text,
		type: type,
		timeout : 15000,
		dismissQueue: true,
		maxVisible: 5,
		layout: 'center',
		theme: 'defaultTheme'

	});
}


function noty_infos(){


	


	jQuery(".messages_notify #message-contact_sended" ).each( function(){

		generate_10('success', 'Wiadomość została poprawnie do nas przesłana.<br> Oczekuj odpowiedzi w najbliższym możliwym terminie.');

	});
	

	jQuery(".messages_notify #message-settings_saved" ).each( function(){
	    
		generate_10('success', 'Ustawienia zaktualizowane')

	});

	jQuery(".messages_notify #message-banned_user" ).each( function(){
	    
		generate_30('error', 'Twoje konto zostało zablokowane przez Administrację portalu')

	});

	jQuery(".messages_notify #message-settings_fill" ).each( function(){

		generate_10('error', 'Uzupełnij dane w sekcji "Podstawowe"');

	});

	jQuery(".messages_notify #message-profil_successful_edited" ).each( function(){

		generate_10('success', 'Dane poprawnie uzupełnione.<br> Możesz teraz przejść do pozostałych sekcji portalu.');

	});

	jQuery(".messages_notify #message-data_saved" ).each( function(){

		generate_10('success', 'Dane zostały zapisane.<br> Teraz uzupełnij pozostałe dane na tej stronie.');

	});

	jQuery(".messages_notify #message-data_notsaved" ).each( function(){

		generate_20('error', 'Błąd podczas ustawienia loginu<br><br>Login zawierana niedozwolone znaki <br> ( tylko litery od A do Z oraz liczby )<br><br> lub jest zbyt długi ( 16 znaków ) <br><br> lub taki login już istnieje na portalu.' );

	});

	jQuery(".messages_notify #message-account_connected_with_fb" ).each( function(){

		generate_10('success', 'Konto zostało powiązane z profilem FaceBook-a');

	});


	jQuery(".messages_notify #message-email_removed" ).each( function(){
	    
		generate_30('error', 'Adres emali został usunięty z naszych Serwerów')

	});

	jQuery(".messages_notify #message-activity_sended" ).each( function(){

		generate_10('success', 'Zaproszenie do aktywności<br>zostało poprawnie wysłane');

	});


	jQuery(".messages_notify #message-user_banned" ).each( function(){

		generate_10('error', 'UŻYTKOWNIK ZOSTAŁ ZABLOKOWANY<br>ODBLOKOWANIE NIE JEST KOŻLIWE');

	});

	jQuery(".messages_notify #message-user_not_banned" ).each( function(){

		generate_10('info', 'Ups, coś poszło nie tak.<br>Użytkownik nie został zablokowany.<br>Skontaktuj sie z nami');

	});



	jQuery(".messages_notify #message-blink_sended" ).each( function(){

		generate_10('success', 'Zainteresowanie zostało poprawnie wysłane do użytkownika');

	});

	jQuery(".messages_notify #message-message_sended" ).each( function(){

		generate_10('success', 'Twoja wiadomość została poprawnie wysłana do użytkownika');

	});



	jQuery(".messages_notify #message_not_sended" ).each( function(){
	    
		generate_30('error', 'Wiadomość nie została wysłana.<br> Jeżeli uważasz, że to błąd, napisz do nas')

	});


	jQuery(".messages_notify #message-user_deleted" ).each( function(){

		generate_10('information', 'Ogłoszenie zostało pomyślnie usunięte wraz ze wszystkimi danymi');

	});

	jQuery(".messages_notify #message-user_not_deleted" ).each( function(){

		generate_10('error', 'Ogłoszenie nie zostało pomyślnie usunięte. Wprowadź poprawny email oraz hasło.');

	});


	jQuery(".messages_notify #message-email_removed" ).each( function(){

		generate_10('success', 'Adres email został trwale usunięty z naszych serwerów');

	});

	jQuery(".messages_notify #message-email_validated" ).each( function(){
	    
		generate_10('success', 'Adres email został potwierdzony.<br> Jeżeli wpis spełnia wymagania będzie niebawem udostępniony.<br> Dziękujemy za korzystanie z portalu <b>TeRandki.pl</b>')

	});

		

}



function buttons_move_anim(){

	jQuery('.articles_pils_row .card-header').on('mouseenter', function(e){
	
		jQuery(this).find('button').find('span').css('padding-left','4px');

	});

	jQuery('.articles_pils_row .card-header').on('mouseleave', function(e){
		
		jQuery(this).find('button').find('span').css('padding-left','0px');

	});

}

var cnt = 1;
var refreshInterval = setInterval(pils_home_page_refresh,3000);


function pils_home_page_refresh(){
		


	if ( cnt == 4 ){

		jQuery('.pils_row .pils_col_l .nav-pills .nav-link#v-pills-' + 1 + '-tab').addClass('active');
		jQuery('.pils_row .pils_col_l .nav-pills .nav-link#v-pills-' + 4 + '-tab').removeClass('active');

		jQuery('.pils_col_r .tab_content .tab-pane#v-pills-' + 1 ).addClass('active').addClass('show');
		jQuery('.pils_col_r .tab_content .tab-pane#v-pills-' + 4 ).removeClass('active').removeClass('show');

		cnt = 0;

	}

	if ( jQuery('.pils_row .pils_col_l .nav-pills .nav-link#v-pills-' + cnt + '-tab').hasClass('active') ){
			
		jQuery('.pils_row .pils_col_l .nav-pills .nav-link#v-pills-' + cnt + '-tab').removeClass('active');
		jQuery('.pils_row .pils_col_l .nav-pills .nav-link#v-pills-' + ( cnt + 1 ) + '-tab').addClass('active');


		jQuery('.pils_col_r .tab_content .tab-pane#v-pills-' + cnt ).removeClass('active').removeClass('show');
		jQuery('.pils_col_r .tab_content .tab-pane#v-pills-' + ( cnt + 1 ) ).addClass('active').addClass('show');
			
	}

	cnt++;
		
}

function pils_home_page(){

	jQuery('.pils_row .pils_col_l .nav-pills .nav-link').on('click', function(){
 		
 		clearInterval( refreshInterval );
 		cnt = 0;
 		
		for ( var i = 1 ; i <= 4 ; i++ ){

			jQuery('.pils_row .pils_col_l .nav-pills .nav-link#v-pills-' + i + '-tab').removeClass('active');
			jQuery('.pils_col_r .tab_content .tab-pane#v-pills-' + i ).removeClass('active').removeClass('show');

		}

		var div_id = jQuery(this).attr('id');
		var div_id_n = parseInt( jQuery(this).attr('id').match(/\d+/) );

		cnt = div_id_n;
		
		jQuery('#' + div_id ).addClass('active');
		jQuery('.pils_col_r .tab_content .tab-pane#v-pills-' + div_id_n ).addClass('active').addClass('show');

		refreshInterval = setInterval(change_section_3_images,3000);

	});

}



function top_carousel(){

	"use strict";

	jQuery('.owl-carousel.home_carousel').owlCarousel({

		loop:false,
    	margin:0,
    	nav:false,
    	items:1,
    	autoplay:false,
    	dots:true,
    	autoplayTimeout:3500,
    	autoplayHoverPause:false,
    	rewind:true

	});

}