// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'bootstrap'
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/css/all"


var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

Rails.start()
//Turbolinks.start()
ActiveStorage.start()


require('packs/fancybox/jquery.fancybox.min')
require('packs/noty/jquery.noty')
require('packs/noty/themes/default')
require('packs/noty/layouts/topRight')
require('packs/noty/layouts/center')

require('packs/cookies/simple-cookies')


require('packs/owl_carousel/owl.carousel.min')

require('packs/global')